$(function() {
  const LS_SIDEBAR_KEY = 'orthodent-sidebar-opened';
  const shouldSidebarBeOpened = () => localStorage && localStorage.getItem(LS_SIDEBAR_KEY) === "true";
  const setShouldSidebarBeOpened = (val) => localStorage && localStorage.setItem(LS_SIDEBAR_KEY, val);

  // Toggle the correct classes on the sidebar and the content
  const toggleSidebar = () => {
    $('.filter-sidebar').toggleClass('collapsed');
    $('.not-filter-content').toggleClass('col-md-10 col-md-12');
    isSidebarOpened = !isSidebarOpened;
    setShouldSidebarBeOpened(isSidebarOpened);
  }

  let isSidebarOpened = false;

  // // Initialize the sidebar in the correct state
  // if (shouldSidebarBeOpened() && !isSidebarOpened) {
  //   toggleSidebar();
  // }

  // When the filter button is clicked, toggle the sidebar
  $('.toggle-filter-sidebar').on('click', toggleSidebar);
});
