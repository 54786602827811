import $ from 'jquery';
import 'bootstrap-sass';

// Dashboard theme
import 'gentelella/build/js/custom';

// For datepickers
import '@fengyuanchen/datepicker/dist/datepicker';
import '@fengyuanchen/datepicker/i18n/datepicker.fr-FR';

// For <select> tags
import 'select2';

// For form validation
import 'parsleyjs';
import 'parsleyjs/dist/i18n/fr';

// Utilities
import _ from 'lodash';

import './_enable_datepicker.js';
import './_enable_select2.js';
import './_password_see.js';
import './_filter_sidebar.js';
import './praticiens/tunnel.js';
import './patients/create-form.js';

import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.start()
