// praticiens/coordonnees-form.js

var findInputGroupId = require('./_findInputGroupId.js');

(function(){

    // When trying to add an "email" line
    $('.coordonnees-email-add-link').on('click', function(e){
        e.preventDefault();

        $('.emails-table tbody').append(generateEmailLine(findInputGroupId($(this))));
    });

    // To remove lines
    $('body').on('click', '.input-table .remove-link', function(e){
        e.preventDefault();

        $(this).closest('tr').remove();
    });

    function generateEmailLine(group_id)
    {
        return (
            '<tr>' +
                '<td class="icon-column">' +
                    '<a href="#">' +
                        '<i class="fa fa-minus-circle fa-lg remove-link"></i>' +
                    '</a>' +
                '</td>' +
                '<td class="label-column">' +
                    '<input type="text" name="email_label[]" class="form-control" value="Perso" data-parsley-group="block-'+ group_id +'" required>' +
                '</td>' +
                '<td>' +
                    '<input type="email" name="email_adresse[]" class="form-control" placeholder="jean.dupont@cabinet.fr" data-parsley-group="block-'+ group_id +'" required>' +
                '</td>' +
            '</tr>'
        );
    }

})();
