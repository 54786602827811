// praticiens/relations-form.js

(function(){

    $('.friends-add-link').on('click', function(e){
        e.preventDefault();

        $('.friends-table tbody').append(generatePraticienLine('amis'));
        $('.friends-table select[enable-select2]').select2({
            width: '100%',
        });
    });

    $('.enemies-add-link').on('click', function(e){
        e.preventDefault();

        $('.enemies-table tbody').append(generatePraticienLine('ennemis'));
        $('.enemies-table select[enable-select2]').select2({
            width: '100%',
        });
    });

    if(typeof praticiens_select_data !== 'undefined')
    {
        var praticiens_options = praticiens_select_data.reduce(function(s,p){
            return s + '<option value="'+ p.id +'">'+ p.nom +' '+ p.prenom +'</option>';
        }, "");
    }

    function generatePraticienLine(field_name)
    {
        return (
            '<tr>' +
                '<td class="icon-column">' +
                    '<a href="#">' +
                        '<i class="fa fa-minus-circle fa-lg remove-link"></i>' +
                    '</a>' +
                '</td>' +
                '<td>' +
                    '<select class="form-control" name="'+ field_name +'[]" enable-select2>' +
                        praticiens_options +
                    '</select>' +
                '</td>' +
            '</tr>'
        );
    }
})();
