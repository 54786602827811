// praticiens/coordonnees-form.js

var findInputGroupId = require('./_findInputGroupId.js');

(function(){
    // When trying to add a "telephone" line
    $('.coordonnees-telephone-add-link').on('click', function(e){
        e.preventDefault();

        $('.telephones-table tbody').append(generateTelephoneLine(findInputGroupId($(this))));
    });

    // To remove lines
    $('body').on('click', '.input-table .remove-link', function(e){
        e.preventDefault();

        $(this).closest('tr').remove();
    });

    function generateTelephoneLine(group_id)
    {
        return (
            '<tr>' +
                '<td class="icon-column">' +
                    '<a href="#">' +
                        '<i class="fa fa-minus-circle fa-lg remove-link"></i>' +
                    '</a>' +
                '</td>' +
                '<td class="label-column">' +
                    '<input type="text" name="telephone_label[]" class="form-control" value="Domicile" data-parsley-group="block-'+ group_id +'" required>' +
                '</td>' +
                '<td>' +
                    '<input type="text" name="telephone_numero[]" class="form-control" placeholder="0123456789" data-parsley-group="block-'+ group_id +'" pattern="(\\+\\d)?(\\d{2}[. ]?){5}" required>' +
                '</td>' +
            '</tr>'
        );
    }

})();
