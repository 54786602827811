// patients/create-form.js

(function(){
    $('select#recommande_par').change(function(e){
        var val = $(this).find('option:selected').first().val();

        if(val == "") {
            $("textarea[name=nouveau_description]").prop('disabled', false);
        } else {
            $("textarea[name=nouveau_description]").prop('disabled', true);
        }
    });
})();
