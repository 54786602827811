
// praticiens/tunnel.js

require('../components/adresses_list_input.js');
require('../components/emails_list_input.js');
require('../components/telephones_list_input.js');

require('./tunnel-steps/famille-form.js');
require('./tunnel-steps/relations-form.js');

(function(){
    // Next button
    $('.step-container .next-button').on('click', function(){
        var stepId = $(this).closest('.step-container').attr('data-step-id');

        if ($('.praticiens-form').parsley().validate({group: 'block-' + stepId}))
            gotoStep(+stepId + 1);
    });

    // Previous button
    $('.step-container .prev-button').on('click', function(){
        var stepId = $(this).closest('.step-container').attr('data-step-id');
        gotoStep(+stepId - 1);
    });

    // Submit button
    $('.praticiens-form .submit-button').on('click', function(){
        // Disables parsley on submit because it aggressively revalidates all fields,
        // even the ones that are not necessary (family members form for instance)
        $('.praticiens-form').parsley().destroy();
    });

    function gotoStep(step)
    {
        // Hides all steps
        $('.step-container').hide();

        // Show the step
        $('.step-container[data-step-id='+ step +']').show();
    }

    // Gets all the form steps
    var $sections = $('.form-section');

    // Sets the sections and their fields up
    $sections.each(function(index, section) {
        // Sets the data-step-id of the group
        $(section).attr('data-step-id', index);

        // Sets the block-id of the inputs of the group
        $(section).find(':input:not(.no-auto-parsley :input)').attr('data-parsley-group', 'block-' + index);
    });

})();
