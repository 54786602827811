// praticiens/famille-form.js

(function(){
    // The circle button, allowing the user to open the form
    $('.family-card-add-link').on('click', function(e){
        e.preventDefault();

        showForm();
    });

    // The cancel button on the form
    $('.family-card-form-cancel-button').on('click', function(e){
        e.preventDefault();

        hideForm();
    });

    // The submit button on the form
    $('.family-card-form-submit-button').on('click', function(e){
        e.preventDefault();

        var fields = {
            nom: $('.family-card-form-nom'),
            prenom: $('.family-card-form-prenom')
        };

        // Display the errors
        fields.nom.parsley().validate();
        fields.prenom.parsley().validate();

        // If everything is valid, proceed
        if(fields.nom.parsley().isValid() && fields.prenom.parsley().isValid()) {
            hideForm();

            // Resets ui for the fields
            fields.nom.parsley().reset();
            fields.prenom.parsley().reset();

            var data = {
                type: $('.family-card-form-type').val(),
                nom: $('.family-card-form-nom').val(),
                prenom: $('.family-card-form-prenom').val(),
                dob: $('.family-card-form-date-naissance').val(),
                sexe: $('.family-card-form-sexe:checked').val(),
            }

            // Adds the card
            $('.family-card-add-link-container').before(generateFamilyCard(data));

            clearForm();
        } else {
            console.error('not good');
        }
    });

    // Allows to remove cards
    $('body').on('click', '.family-card-remove-icon', function(e){
        e.preventDefault();

        $(this).closest('.family-card').remove();
    });

    function showForm()
    {
        // Hides the add card
        $('.family-card-add-link-container').hide();

        // Shows the form card
        $('.family-card-form').show();
    }

    function hideForm()
    {
        // Hides the form card
        $('.family-card-form').hide();

        // Shows the add card
        $('.family-card-add-link-container').show();
    }

    function clearForm()
    {
        // $('.family-card-form-type').val(''); //TODO
        $('.family-card-form-nom').val('');
        $('.family-card-form-prenom').val('');
        $('.family-card-form-date-naissance').val('');
        // $('.family-card-form-sexe:checked').val(''); //TODO
    }

    function generateFamilyCard(data)
    {
        var img = data.type == 'conjoint' ? 'conjoint' : data.sexe == 'homme' ? 'homme' : 'femme';

        return (
            '<div class="family-card">'+
                '<input type="hidden" name="famille_prenom[]" value="'+ data.prenom +'">'+
                '<input type="hidden" name="famille_nom[]" value="'+ data.nom +'">'+
                '<input type="hidden" name="famille_dob[]" value="'+ data.dob +'">'+
                '<input type="hidden" name="famille_type[]" value="'+ data.type +'">'+
                '<input type="hidden" name="famille_sexe[]" value="'+ data.sexe +'">'+
                '<a href="#">'+
                    '<div class="img-container">'+
                        '<img src="/images/'+ img +'_avatar.png" class="img-responsive">'+
                        '<i class="fa fa-times family-card-remove-icon"></i>'+
                    '</div>'+
                    '<strong>'+ data.prenom + ' ' + data.nom +'</strong>'+
                    '<small>' + data.dob + ' ('+ ucfirst(data.type) +')</small>'+
                '</a>'+
            '</div>'
        );
    }

    function ucfirst(string)
    {
        return string[0].toUpperCase() + string.slice(1);
    }
})();
