$(function(){
  $('.password-see-button').on('click', function(){
    event.preventDefault();

    var iconSpan = $(this).find('span.fa');
    iconSpan.toggleClass('fa-eye');
    iconSpan.toggleClass('fa-eye-slash');

    var field = $(this).parent().siblings('input').first();
    field.attr('type', field.attr('type') == 'password' ? 'text' : 'password');
  });
});
