// praticiens/coordonnees-form.js

var findInputGroupId = require('./_findInputGroupId.js');

(function(){
    // When trying to add an "adresse" line
    $('.coordonnees-adresse-add-link').on('click', function(e){
        e.preventDefault();

        $('.adresses-table tbody').append(generateAdresseLine(findInputGroupId($(this))));
    });

    // To remove lines
    $('body').on('click', '.input-table .remove-link', function(e){
        e.preventDefault();

        $(this).closest('tr').remove();
    });

    function generateAdresseLine(group_id)
    {
        return (
            '<tr>' +
                '<td class="icon-column">' +
                    '<a href="#">' +
                        '<i class="fa fa-minus-circle fa-lg remove-link"></i>' +
                    '</a>' +
                '</td>' +
                '<td class="label-column">' +
                    '<input type="text" name="adresse_label[]" class="form-control" value="Domicile" placeholder="Domicile" data-parsley-group="block-'+ group_id +'" required>' +
                '</td>' +
                '<td>' +
                    '<div class="row">' +
                        '<div class="col-md-12">' +
                            '<input type="text" name="adresse_rue[]" class="form-control" placeholder="Rue" data-parsley-group="block-'+ group_id +'" required>' +
                        '</div>' +
                    '</div>' +
                    '<div class="row">' +
                        '<div class="col-md-4 input-column">' +
                            '<input type="text" name="adresse_code_postal[]" class="form-control col-md-6" placeholder="75016" data-parsley-group="block-'+ group_id +'" pattern="[0-9 ]{2,}" required>' +
                        '</div>' +
                        '<div class="col-md-8 input-column">' +
                            '<input type="text" name="adresse_ville[]" class="form-control col-md-6" placeholder="Paris" data-parsley-group="block-'+ group_id +'" required>' +
                        '</div>' +
                    '</div>' +
                '</td>' +
            '</tr>'
        );
    }

})();
